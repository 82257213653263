import React from 'react';

function Texts() {
  return (
    <div>
      <h1>Página de Textos</h1>
      <p>¡Bienvenido a la página de Textos!</p>
    </div>
  );
}

export default Texts;